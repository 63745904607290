import {configureStore} from "@reduxjs/toolkit";
import mindMapReducer from '../components/mindmap/MindMapSlice'


const createStore = (initialState) => configureStore({
    reducer: {
        mindMap: mindMapReducer
    },
    preloadedState: initialState
});

export default createStore;
