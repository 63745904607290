import React from "react";
import "./Nav-screen.sass";
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro";
import SubNav from "../page-content/Sub-nav";
import "./Social-relations.sass";
import { ReactComponent as SosialeRelasjoner } from "../../images/categories/sosiale-relasjoner.svg";

import { socialRelations } from "../../content";

const SocialRelations = () => {
  return (
    <section className="social-relations page">
      <div className="content-wrapper">
        <Backbutton linkUrl="/valg" title="Forsiden" />
        <div className="top-image-wrapper">
          <SosialeRelasjoner />
        </div>
        <PageIntro soundfile={socialRelations.pageIntroAudio}>
          <p>{socialRelations.pageIntro}</p>
        </PageIntro>
        <SubNav navItems={socialRelations.navText} />
      </div>
    </section>
  );
};

export default SocialRelations;
