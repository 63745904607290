import React, { useEffect, useState } from "react";

import RelationMapSvg from "../../images/relasjonskart.svg";
import "./Mindmap.sass";

const MindMap = () => {
  const [items, setItems] = useState(["", "", "", "", "", "", "", "", ""]);
  useEffect(() => {
    const data = localStorage.getItem("map-list");
    if (data) {
      setItems(JSON.parse(data));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("map-list", JSON.stringify(items));
  }, [items]);

  function handleChange(targetValue, index) {
    const newArray = [...items];
    newArray[index] = targetValue;
    setItems(newArray);
  }

  return (
    <section className="mindmap">
      <img src={RelationMapSvg} alt="" />
      {items.map((item, index) => (
        <textarea
          className="relation-field"
          id={`field${index}`}
          key={index}
          onChange={(e) => handleChange(e.target.value, index)}
          value={item}
        />
      ))}
    </section>
  );
};

export default MindMap;
