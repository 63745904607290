import React from 'react';

import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import Collapse from "../page-content/Collapse-content"

import {ladderModel} from "../../content";



const LadderModel = () => {


    return (
        <section className="ladder-model page">
            <div className="content-wrapper">
                <Backbutton linkUrl="/problemlosning" title="Problemløsing"    />
                <h1>{ladderModel.title}</h1>
                <PageIntro soundfile={ladderModel.pageIntroAudio}>
                    <p>{ladderModel.pageIntro}</p>
                </PageIntro>
                <div className="imageWrapper">
                    <img src="/images/misc/stigemodellen.png" alt=""/>
                </div>
                <h2 className="sound-heading">Slik bruker du stigemodellen</h2>
                <Collapse listItems={ladderModel.collapseContent} soundfile={ladderModel.audioManuscript} />
            </div>
        </section>
    )
}

export default LadderModel;
