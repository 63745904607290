import { useState } from "react";
import "./Collapse-content.sass";
import usePlaying from "./hooks/usePlaying";

const CollapseContent = ({ collapsed, soundfile, listItems }) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const { isPlaying, setPlaying } = usePlaying({ audio: soundfile });

  const textList = listItems.map((item, index) => (
    <p key={index} className="item-number">
      {item}
    </p>
  ));

  return (
    <section className="collapse-wrapper">
      <div className="button-container">
        <svg
          onClick={() => setPlaying(!isPlaying)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 232.72 103.75"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                d="M91.85,102.91c25.15,1.26,111.66,3.56,129.59-14.49,6.62-6.67,9.86-15.91,11.05-25.89.77-6.48-.6-19.3-1.26-25.58-1.71-16.26-16.58-25.24-27-30.31C187.16-1.66,58-1,40.1,2.31,26.42,4.85,12.51,9,6,24.22,1.48,34.81-1.5,50.4.79,62.57,4.32,81.34,16.06,96.73,30.9,99.46Z"
                fill="#0c856c"
              />
              <path
                d="M96.43,66.84c-.11-.15-.23-.29-.32-.43l-.49-.8-.71.63a4.24,4.24,0,0,1-.4.31,3.11,3.11,0,0,0-1.07,1.12A7.42,7.42,0,0,0,93,69.12a4.66,4.66,0,0,1-.5,1.41,15.05,15.05,0,0,1-18.73,6,24.83,24.83,0,0,1-5.66-3.82c-.36-.29-.71-.59-1.07-.87a9.81,9.81,0,0,1-2.13-2.86c-.87-1.54-1.71-3.13-2.51-4.67-.6-1.13-1.19-2.26-1.81-3.39s-1.31-2.33-2-3.49c-1.21-2.1-2.47-4.27-3.5-6.48-3.56-7.6-2.61-14.22,2.83-19.67A29,29,0,0,1,64.63,26q8.59-4.61,16.25-.25a17.13,17.13,0,0,1,8.18,10.82,2.72,2.72,0,0,0,1.1,1.31,3.71,3.71,0,0,1,.42.35l.77.74.49-.93.25-.47a9.93,9.93,0,0,0,.64-1.31A1.85,1.85,0,0,0,92.66,35l0-.09A21,21,0,0,0,76.7,20.26a15.45,15.45,0,0,0-3.3-.35c-6.29,0-13.67,3.56-18.79,9.08-4,4.3-5.91,9-5.37,13.08,0,.5,0,1,.06,1.51A21.11,21.11,0,0,0,49.6,47c.84,4.33,3,8.07,5.5,12.16.37.6.75,1.2,1.13,1.79a40.05,40.05,0,0,1,3.14,5.59c1.82,4.3,4.68,7.81,9.26,11.38a17.88,17.88,0,0,0,10.81,3.35A18.77,18.77,0,0,0,95.92,72.2a10.09,10.09,0,0,0,1.21-3.73A2.21,2.21,0,0,0,96.43,66.84Z"
                fill="#fff"
              />
              <path
                d="M78.75,41.33a2.78,2.78,0,0,0-1.65-.4c-3.08.54-5.32,1.81-6.65,3.8A38.6,38.6,0,0,0,66.63,52a7.68,7.68,0,0,0,1.47,8.6,3.69,3.69,0,0,1,.55.88,7,7,0,0,0,.63,1c.32.4.63.81,1,1.22a46.51,46.51,0,0,0,3.85,4.52,5.59,5.59,0,0,0,3.6,1.43H78c6.21-.5,8.91-3,9.61-8.73.39-3.27-1.9-5.07-3.93-6.66l-.3-.24a4.16,4.16,0,0,1-.55-6.44c2.46-2.76,2.42-5.39-.12-8-2.18-2.28-4.56-4.44-6.85-6.52l-.71-.64A8.14,8.14,0,0,0,72.71,31,13.09,13.09,0,0,0,67.49,30a13.91,13.91,0,0,0-10.8,5l-.29.37.22.41c.45.83,1,1.76,1.92,1.76a3.27,3.27,0,0,0,2-1.08c3.32-2.9,6.9-3.6,10.43-2a22.94,22.94,0,0,1,8.44,7.22l-.16-.06A3.13,3.13,0,0,1,78.75,41.33Zm.84,1.32.28-.4c.19.26.38.51.56.77A1.26,1.26,0,0,1,80.1,45,5.2,5.2,0,0,0,79,46.25c-2.38,3.64-1.67,7.8,1.82,10.6s3.42,3.35,2.25,6.26a4.77,4.77,0,0,1-4.43,2.8,3.81,3.81,0,0,1-2.88-1.19,33.79,33.79,0,0,1-2.86-3.86c-.66-1-1.33-2-2.08-2.92A3.88,3.88,0,0,1,70.06,54l.15-.43c1.46-4.16,2.85-8.1,7.88-9.25.65-.15,1-.73,1.24-1.25A3.81,3.81,0,0,1,79.59,42.65Z"
                fill="#fff"
              />
              <path
                d="M49.39,61.94a3.69,3.69,0,0,1,.24-.53l.53-1-1.68-.17-1.65-.16a1.07,1.07,0,0,0-.65.24l-3.42,1.57c-2.76,1.26-9.35,4.64-12.1,5.92a9.72,9.72,0,0,0-2.2,1.27,2,2,0,0,0-.48,2A1.63,1.63,0,0,0,29,72l.29.14.35.22L31,71.76c.67-.29,1.31-.56,1.94-.85l2.18-1c3.08-1.4,10.07-5,13.16-6.48A2.57,2.57,0,0,0,49.39,61.94Z"
                fill="#fff"
              />
              <path
                d="M30.84,25c.21,0,.41.08.59.13a48.53,48.53,0,0,0,5.08,1.1,27,27,0,0,1,8,2.25,2.19,2.19,0,0,0,.58.19l.42.11.37.09.31-.22.22-.12c.44-.23,1-.55,1.15-1.16a2.23,2.23,0,0,0-1.26-2.13c-2.77-1-4.92-1.67-7-2.2s-4.39-1-6.54-1.38l-.49-.09a3.69,3.69,0,0,1-.65-.23A4,4,0,0,0,30.18,21a1.66,1.66,0,0,0-1.63,1.28,1.88,1.88,0,0,0,.1,1.64A3.1,3.1,0,0,0,30.84,25Z"
                fill="#fff"
              />
              <path
                d="M39.67,44.5a2.36,2.36,0,0,0,1.42-1.28,4.69,4.69,0,0,1,.34-.49l.67-.82-1-.34-.51-.2a3.58,3.58,0,0,0-1.64-.4c-1,.07-1.91.24-2.83.39-.51.09-1,.18-1.54.25l-3,.41c-2.16.29-4.39.59-6.57.95A2.22,2.22,0,0,0,23.62,44c-.1.14-.2.28-.3.4l-.53.56,1.95,1.6L31,45.75l2.18-.29C35.29,45.18,37.51,44.89,39.67,44.5Z"
                fill="#fff"
              />
              <path
                d="M54.09,74.51a2.52,2.52,0,0,0-2-.07c-2.6,1.65-7.46,4.79-11,7.27a2.26,2.26,0,0,0-.69,2c0,.72,1,1.22,1.56,1.22h.16A4.58,4.58,0,0,0,44,84.21c2.5-1.64,7.24-4.68,10.07-6.62a4.18,4.18,0,0,0,.85-.84c.17-.2.38-.45.67-.76l.87-.95-1.3-.26C54.76,74.7,54.41,74.6,54.09,74.51Z"
                fill="#fff"
              />
              <path
                d="M121.26,30.47V59.23L133,57.89l-.22,8.23-18.9.44C113.48,54.1,113.1,43,112.81,30Z"
                fill="#fff"
              />
              <path
                d="M139.93,43.46,144,54.77l3.29-11.13,7,.67Q148.53,69.5,145.08,73t-11.66,4.06l-.54-8.43q4.77,1.47,8.28-2.77L132.5,45.75Z"
                fill="#fff"
              />
              <path
                d="M155.6,49.46l0-6.9,5.35-.3-.37-11.35,7.12-.26-.28,10.89,5.78-.08.27,6.91-5.79.29q-1.41,20,4.89,8.35l4.88.8q-.41,6.66-3.67,8.46c-2.18,1.21-5.12,1.47-8.8.78s-4.94-6.73-3.76-18.11Z"
                fill="#fff"
              />
              <path
                d="M177.34,49.74l-.22-6.89,5.34-.44-.67-11.34,7.11-.45v10.9l5.78-.23.45,6.9-5.78.44q-.9,20,5.11,8.23l4.89.66q-.22,6.67-3.44,8.56t-8.79,1q-5.55-.89-4.22-18Z"
                fill="#fff"
              />
            </g>
          </g>
        </svg>

        <p>eller</p>
        <svg
          onClick={() => setIsCollapsed(!isCollapsed)}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 232.72 103.75"
        >
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path
                d="M91.85,102.91c25.15,1.26,111.66,3.56,129.59-14.49,6.62-6.67,9.86-15.91,11.05-25.89.77-6.48-.6-19.3-1.26-25.58-1.71-16.26-16.58-25.24-27-30.31C187.16-1.66,58-1,40.1,2.31,26.42,4.85,12.51,9,6,24.22,1.48,34.81-1.5,50.4.79,62.57,4.32,81.34,16.06,96.74,30.9,99.46Z"
                fill="#0c856c"
              />
              <path
                d="M109.53,57.72a8.77,8.77,0,0,1-.27-1.44,9.31,9.31,0,0,0-.28-1.53,16.36,16.36,0,0,0-1.27-3.46c-.77-1.41-1.61-2.79-2.42-4.13-.41-.67-.82-1.34-1.21-2a11.64,11.64,0,0,0-3.53-3.86c-5.14-6.15-17.27-20.58-18.38-21.38-1.42-1-5.47-1.42-2.84,1.62C81.21,23.69,90.05,34,95,39.7l-1.33,0a23.4,23.4,0,0,0-13.84,4c-3.46,2.43-6.39,4.79-7,9.13a1.4,1.4,0,0,1-.67.53c-3.59.35-6.84.58-9.94.69A2.14,2.14,0,0,1,61,53.27c-.77-.93-1.52-1.93-2.24-2.9a39.74,39.74,0,0,0-4.31-5.15A16.11,16.11,0,0,0,42.1,40.73a41.71,41.71,0,0,0-8.42,1.74l-.28.08c5.06-5.86,13-15.08,14.77-17.11,2.63-3-1.42-2.64-2.84-1.62-1.2.85-15.16,17.51-19.43,22.63a18.92,18.92,0,0,0-5.61,9.26c-2,7.08,2.1,16.26,9,20.07a21,21,0,0,1,2.73,1.68,13.92,13.92,0,0,0,9.19,2.9c5.64-.19,10.76-2.44,15.63-6.88a11.1,11.1,0,0,0,2.2-3.25l.18-.33c2.26-4.33,3.07-8.15,2.54-12,0,0,0,0,0-.07l10.38-.68c0,.15,0,.29,0,.44,0,.83,0,1.61,0,2.38a26.84,26.84,0,0,0,1.41,10.11A12.25,12.25,0,0,0,79,77a23.39,23.39,0,0,0,12.34,3.55c.51,0,1,0,1.53-.05,5.24-.34,9.33-2.14,12.17-5.38C109.94,69.49,111.42,63.8,109.53,57.72ZM49.82,74.11l-.17.08c-2.84,1.32-5.53,2.58-8.43,2.58A9.85,9.85,0,0,1,39,76.51a19.11,19.11,0,0,1-4.06-1.67l-.85-.43a18.33,18.33,0,0,1-1.88-1.1c-.47-.3-.94-.61-1.43-.88-5.15-2.89-8.54-10.58-7-15.82,1.64-5.5,5.09-9,10.25-10.48,1.84-.51,3.81-1,6-1.35a17.43,17.43,0,0,1,3-.28c5,0,9.06,2.39,12.48,7.31,2.56,3.69,2.64,4.3,2.66,9.67C57.55,65.85,56,71.23,49.82,74.11Zm56.88-11c-1.25,8.78-6.76,13.81-15.11,13.81A21.91,21.91,0,0,1,89,76.7a27.49,27.49,0,0,1-4.49-1c-4.95-1.47-7.85-5-8.37-10.34a60.24,60.24,0,0,1,.15-9.68l.12-1.82c.25-3.84,3.16-5.88,6-7.37a25.13,25.13,0,0,1,11.94-3.08l.65,0c2.6,0,4.59,1.45,6.66,4.85q.35.59.72,1.17c.63,1,1.29,2.07,1.84,3.14a19.46,19.46,0,0,1,1.36,3.43,37.36,37.36,0,0,1,.93,4.21l.06.34A8.66,8.66,0,0,1,106.7,63.06Z"
                fill="#fff"
              />
              <path
                d="M137.8,30.85V59.61l11.78-1.34-.22,8.23-18.9.44c-.44-12.46-.82-23.58-1.11-36.56Z"
                fill="#fff"
              />
              <path
                d="M177.73,63.41l2.47-6.34q9.33,6,12.4,3.11t-3.71-4.67q-6.78-1.73-9.1-7.07t2.5-8.88c3.22-2.35,11.55-4.52,16.88-.31l-1.63,6.39q-6.45-2.85-9-1.13c-1.72,1.14-1.51,2.4.63,3.76a28,28,0,0,0,7.06,3.12c2.6.78,4,2.82,4.12,5.94s-1.33,6-4.39,8.52S185.17,69.36,177.73,63.41Z"
                fill="#fff"
              />
              <path
                d="M167.84,56.75q-1.58,4.08-5.34,3.66c-2.5-.28-4.19-3.36-4.73-5L175,52.06q-.69-14.52-10.91-14.91t-11.9,12.76Q150.54,63.07,157.16,66t11.32.55q4.71-2.36,7.2-10.42Zm1-8.85L158.45,50c0,.06,0,.12-.08.17l-.09,0c.33-1.12.44-2.78,1.42-4.16C163,42.76,166,43.72,168.82,47.9Z"
                fill="#fff"
              />
            </g>
          </g>
        </svg>
      </div>
      <div
        className={`collapse-content ${isCollapsed ? "expanded" : "collapsed"}`}
      >
        {textList}
      </div>
    </section>
  );
};

export default CollapseContent;
