import React from "react";
import "./Listen-to-text.sass";
import usePlaying from "./hooks/usePlaying";

const ListenToText = ({ soundfile, svgColor }) => {
  const { isPlaying, setPlaying } = usePlaying({ audio: soundfile });

  let svgIcon;

  if (svgColor === "dark") {
    svgIcon = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.62 105.28">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              d="M76.42,105.18C86,104.83,104.08,106,122,88a44.15,44.15,0,0,0,12.38-25.75c.77-6.48-.6-19.29-1.26-25.57-1.7-16.27-16.58-25.24-27-30.31C89.06-2,58-.81,40.1,2.51,26.42,5.06,12.51,9.21,6,24.43,1.48,35-1.5,50.6.79,62.78c3.53,18.77,16.31,34.39,28.49,38.4S66.86,105.52,76.42,105.18Z"
              fill="#085b4a"
            />
            <path
              d="M97.3,67.74c-.11-.15-.23-.29-.32-.43l-.49-.8-.71.63a4.24,4.24,0,0,1-.4.31,3.11,3.11,0,0,0-1.07,1.12A7.42,7.42,0,0,0,93.84,70a4.66,4.66,0,0,1-.5,1.41,15.05,15.05,0,0,1-18.73,5.95A24.83,24.83,0,0,1,69,73.56c-.36-.29-.71-.59-1.07-.87a10,10,0,0,1-2.13-2.86c-.87-1.54-1.71-3.13-2.51-4.67-.6-1.13-1.19-2.26-1.81-3.38s-1.31-2.34-2-3.5C58.24,56.18,57,54,56,51.8c-3.56-7.6-2.61-14.22,2.83-19.67A29,29,0,0,1,65.5,26.9q8.59-4.61,16.25-.25a17.13,17.13,0,0,1,8.18,10.82A2.72,2.72,0,0,0,91,38.78a5,5,0,0,1,.43.35l.76.74.49-.93.25-.47a9.93,9.93,0,0,0,.64-1.31,1.85,1.85,0,0,0-.07-1.22l0-.09A21,21,0,0,0,77.57,21.16a15.45,15.45,0,0,0-3.3-.35c-6.29,0-13.67,3.56-18.79,9.08-4,4.3-5.91,9-5.37,13.08,0,.5,0,1,.06,1.51a21.11,21.11,0,0,0,.3,3.43c.85,4.33,3,8.07,5.5,12.16.37.6.75,1.2,1.13,1.79a40.05,40.05,0,0,1,3.14,5.59c1.82,4.3,4.68,7.81,9.26,11.38a17.88,17.88,0,0,0,10.81,3.35A18.77,18.77,0,0,0,96.79,73.1,10.09,10.09,0,0,0,98,69.37,2.21,2.21,0,0,0,97.3,67.74Z"
              fill="#fff"
            />
            <path
              d="M79.62,42.23a2.78,2.78,0,0,0-1.65-.4c-3.08.54-5.32,1.81-6.65,3.8A38.6,38.6,0,0,0,67.5,52.9,7.68,7.68,0,0,0,69,61.5a3.69,3.69,0,0,1,.55.88,7,7,0,0,0,.63,1c.32.4.63.81.95,1.22A46.51,46.51,0,0,0,75,69.13a5.59,5.59,0,0,0,3.6,1.43h.29c6.21-.5,8.91-3,9.61-8.73.39-3.27-1.9-5.07-3.93-6.66l-.3-.24a4.16,4.16,0,0,1-.55-6.44c2.46-2.76,2.42-5.39-.12-8C81.37,38.15,79,36,76.7,33.91L76,33.27a8.23,8.23,0,0,0-2.4-1.37,13.17,13.17,0,0,0-5.23-1,13.91,13.91,0,0,0-10.8,5l-.29.37.22.41c.45.83,1,1.76,1.92,1.76a3.27,3.27,0,0,0,2-1.08c3.32-2.9,6.9-3.6,10.43-2a22.94,22.94,0,0,1,8.44,7.22l-.15-.06A2.81,2.81,0,0,1,79.62,42.23Zm.84,1.32.28-.4c.19.26.38.51.56.77A1.26,1.26,0,0,1,81,45.87a5.2,5.2,0,0,0-1.07,1.28c-2.38,3.64-1.67,7.8,1.82,10.6S85.14,61.1,84,64a4.77,4.77,0,0,1-4.43,2.8,3.81,3.81,0,0,1-2.88-1.19,33.79,33.79,0,0,1-2.86-3.86c-.66-1-1.33-2-2.08-2.92a3.88,3.88,0,0,1-.79-3.91l.15-.43c1.46-4.16,2.85-8.1,7.88-9.25.65-.15,1-.73,1.24-1.25A3.81,3.81,0,0,1,80.46,43.55Z"
              fill="#fff"
            />
            <path
              d="M50.26,62.84a3.69,3.69,0,0,1,.24-.53l.53-1-1.68-.17L47.7,61a1.07,1.07,0,0,0-.65.24l-3.42,1.57c-2.76,1.26-9.35,4.64-12.1,5.92A9.72,9.72,0,0,0,29.33,70a2,2,0,0,0-.48,2,1.63,1.63,0,0,0,1,.91l.29.14.35.22,1.39-.59c.67-.29,1.31-.56,1.94-.85l2.18-1c3.08-1.41,10.07-5,13.16-6.49A2.57,2.57,0,0,0,50.26,62.84Z"
              fill="#fff"
            />
            <path
              d="M31.71,25.93c.21,0,.41.08.59.13a48.53,48.53,0,0,0,5.08,1.1,27,27,0,0,1,7.95,2.25,2.19,2.19,0,0,0,.58.19l.42.11.37.09.31-.21.22-.13c.44-.23,1-.55,1.15-1.16a2.23,2.23,0,0,0-1.26-2.13c-2.77-1-4.92-1.67-7-2.2s-4.39-1-6.54-1.38l-.49-.09a3.69,3.69,0,0,1-.65-.23,4,4,0,0,0-1.43-.37,1.66,1.66,0,0,0-1.63,1.28,1.88,1.88,0,0,0,.1,1.64A3.1,3.1,0,0,0,31.71,25.93Z"
              fill="#fff"
            />
            <path
              d="M40.54,45.4A2.36,2.36,0,0,0,42,44.12a4.69,4.69,0,0,1,.34-.49l.67-.82-1-.34-.51-.2a3.58,3.58,0,0,0-1.64-.4c-1,.07-1.91.24-2.83.39-.51.09-1,.18-1.54.25l-3,.41c-2.16.29-4.39.59-6.57.95a2.22,2.22,0,0,0-1.35,1.06c-.1.14-.2.28-.3.4l-.53.56,1.95,1.6,6.24-.84L34,46.36C36.16,46.08,38.38,45.79,40.54,45.4Z"
              fill="#fff"
            />
            <path
              d="M55,75.41a2.52,2.52,0,0,0-2-.07c-2.6,1.65-7.46,4.79-11,7.27a2.27,2.27,0,0,0-.69,2c0,.72,1,1.22,1.56,1.22H43a4.58,4.58,0,0,0,1.81-.68c2.5-1.64,7.24-4.68,10.07-6.62a4.18,4.18,0,0,0,.85-.84c.17-.2.38-.45.67-.76l.87-1L56,75.68C55.63,75.6,55.28,75.5,55,75.41Z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    );
  } else {
    svgIcon = (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 134.62 105.28">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <path
              d="M76.42,105.18C86,104.83,104.08,106,122,88a44.15,44.15,0,0,0,12.38-25.75c.77-6.48-.6-19.29-1.26-25.57-1.7-16.27-16.58-25.24-27-30.31C89.06-2,58-.81,40.1,2.51,26.42,5.06,12.51,9.21,6,24.43,1.48,35-1.5,50.6.79,62.78c3.53,18.77,16.31,34.39,28.49,38.4S66.86,105.52,76.42,105.18Z"
              fill="#0c856c"
            />
            <path
              d="M97.3,67.74c-.11-.15-.23-.29-.32-.43l-.49-.8-.71.63a4.24,4.24,0,0,1-.4.31,3.11,3.11,0,0,0-1.07,1.12A7.42,7.42,0,0,0,93.84,70a4.66,4.66,0,0,1-.5,1.41,15.05,15.05,0,0,1-18.73,5.95A24.83,24.83,0,0,1,69,73.56c-.36-.29-.71-.59-1.07-.87a10,10,0,0,1-2.13-2.86c-.87-1.54-1.71-3.13-2.51-4.67-.6-1.13-1.19-2.26-1.81-3.38s-1.31-2.34-2-3.5C58.24,56.18,57,54,56,51.8c-3.56-7.6-2.61-14.22,2.83-19.67A29,29,0,0,1,65.5,26.9q8.59-4.61,16.25-.25a17.13,17.13,0,0,1,8.18,10.82A2.72,2.72,0,0,0,91,38.78a5,5,0,0,1,.43.35l.76.74.49-.93.25-.47a9.93,9.93,0,0,0,.64-1.31,1.85,1.85,0,0,0-.07-1.22l0-.09A21,21,0,0,0,77.57,21.16a15.45,15.45,0,0,0-3.3-.35c-6.29,0-13.67,3.56-18.79,9.08-4,4.3-5.91,9-5.37,13.08,0,.5,0,1,.06,1.51a21.11,21.11,0,0,0,.3,3.43c.85,4.33,3,8.07,5.5,12.16.37.6.75,1.2,1.13,1.79a40.05,40.05,0,0,1,3.14,5.59c1.82,4.3,4.68,7.81,9.26,11.38a17.88,17.88,0,0,0,10.81,3.35A18.77,18.77,0,0,0,96.79,73.1,10.09,10.09,0,0,0,98,69.37,2.21,2.21,0,0,0,97.3,67.74Z"
              fill="#fff"
            />
            <path
              d="M79.62,42.23a2.78,2.78,0,0,0-1.65-.4c-3.08.54-5.32,1.81-6.65,3.8A38.6,38.6,0,0,0,67.5,52.9,7.68,7.68,0,0,0,69,61.5a3.69,3.69,0,0,1,.55.88,7,7,0,0,0,.63,1c.32.4.63.81.95,1.22A46.51,46.51,0,0,0,75,69.13a5.59,5.59,0,0,0,3.6,1.43h.29c6.21-.5,8.91-3,9.61-8.73.39-3.27-1.9-5.07-3.93-6.66l-.3-.24a4.16,4.16,0,0,1-.55-6.44c2.46-2.76,2.42-5.39-.12-8C81.37,38.15,79,36,76.7,33.91L76,33.27a8.23,8.23,0,0,0-2.4-1.37,13.17,13.17,0,0,0-5.23-1,13.91,13.91,0,0,0-10.8,5l-.29.37.22.41c.45.83,1,1.76,1.92,1.76a3.27,3.27,0,0,0,2-1.08c3.32-2.9,6.9-3.6,10.43-2a22.94,22.94,0,0,1,8.44,7.22l-.15-.06A2.81,2.81,0,0,1,79.62,42.23Zm.84,1.32.28-.4c.19.26.38.51.56.77A1.26,1.26,0,0,1,81,45.87a5.2,5.2,0,0,0-1.07,1.28c-2.38,3.64-1.67,7.8,1.82,10.6S85.14,61.1,84,64a4.77,4.77,0,0,1-4.43,2.8,3.81,3.81,0,0,1-2.88-1.19,33.79,33.79,0,0,1-2.86-3.86c-.66-1-1.33-2-2.08-2.92a3.88,3.88,0,0,1-.79-3.91l.15-.43c1.46-4.16,2.85-8.1,7.88-9.25.65-.15,1-.73,1.24-1.25A3.81,3.81,0,0,1,80.46,43.55Z"
              fill="#fff"
            />
            <path
              d="M50.26,62.84a3.69,3.69,0,0,1,.24-.53l.53-1-1.68-.17L47.7,61a1.07,1.07,0,0,0-.65.24l-3.42,1.57c-2.76,1.26-9.35,4.64-12.1,5.92A9.72,9.72,0,0,0,29.33,70a2,2,0,0,0-.48,2,1.63,1.63,0,0,0,1,.91l.29.14.35.22,1.39-.59c.67-.29,1.31-.56,1.94-.85l2.18-1c3.08-1.41,10.07-5,13.16-6.49A2.57,2.57,0,0,0,50.26,62.84Z"
              fill="#fff"
            />
            <path
              d="M31.71,25.93c.21,0,.41.08.59.13a48.53,48.53,0,0,0,5.08,1.1,27,27,0,0,1,7.95,2.25,2.19,2.19,0,0,0,.58.19l.42.11.37.09.31-.21.22-.13c.44-.23,1-.55,1.15-1.16a2.23,2.23,0,0,0-1.26-2.13c-2.77-1-4.92-1.67-7-2.2s-4.39-1-6.54-1.38l-.49-.09a3.69,3.69,0,0,1-.65-.23,4,4,0,0,0-1.43-.37,1.66,1.66,0,0,0-1.63,1.28,1.88,1.88,0,0,0,.1,1.64A3.1,3.1,0,0,0,31.71,25.93Z"
              fill="#fff"
            />
            <path
              d="M40.54,45.4A2.36,2.36,0,0,0,42,44.12a4.69,4.69,0,0,1,.34-.49l.67-.82-1-.34-.51-.2a3.58,3.58,0,0,0-1.64-.4c-1,.07-1.91.24-2.83.39-.51.09-1,.18-1.54.25l-3,.41c-2.16.29-4.39.59-6.57.95a2.22,2.22,0,0,0-1.35,1.06c-.1.14-.2.28-.3.4l-.53.56,1.95,1.6,6.24-.84L34,46.36C36.16,46.08,38.38,45.79,40.54,45.4Z"
              fill="#fff"
            />
            <path
              d="M55,75.41a2.52,2.52,0,0,0-2-.07c-2.6,1.65-7.46,4.79-11,7.27a2.27,2.27,0,0,0-.69,2c0,.72,1,1.22,1.56,1.22H43a4.58,4.58,0,0,0,1.81-.68c2.5-1.64,7.24-4.68,10.07-6.62a4.18,4.18,0,0,0,.85-.84c.17-.2.38-.45.67-.76l.87-1L56,75.68C55.63,75.6,55.28,75.5,55,75.41Z"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
    );
  }

  return (
    <section className="listen-to-text">
      <div className="button-container">
        <button onClick={() => setPlaying(!isPlaying)} className="listen-btn">
          {svgIcon}
          <span>Lytt til teksten</span>
        </button>
      </div>
    </section>
  );
};

export default ListenToText;
