import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./Home.sass";

import { ReactComponent as Logo } from "./../../images/logos/robust-logo.svg";
import forskningsraadet from "./../../images/logos/forskningsraadet.png";
import laeringsmiljosentet from "./../../images/logos/laeringsmiljosenteret.png";
import { frontpage } from "../../content";

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(localStorage.getItem("authorized") || "");

  useEffect(() => {
    if (data === "yes") {
      navigate("/valg");
    }
  }, [data, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setData("yes");
    localStorage.setItem("authorized", "yes");
    navigate("/valg");
  };

  return (
    <section className="home page">
      <div className="content-wrapper">
        <div id="logo">
          <Logo />
        </div>

        <div className="videoWrapper">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/451397088"
            width="640"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <div className="texWrapper">
          <p>{frontpage.textContent[0]}</p>
          <p>{frontpage.textContent[1]}</p>
        </div>
        <form onSubmit={handleSubmit} className="login-form">
          <input
            style={{
              backgroundImage: `url(/images/misc/login-btn.svg)`,
              cursor: "pointer",
            }}
            className="submit-btn"
            type="submit"
            value="Kom i gang!"
          />
        </form>
        <div className="logoWrapper">
          <img src={forskningsraadet} alt="" />
          <img src={laeringsmiljosentet} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Home;
