import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import Collapse from "../page-content/Collapse-content"


import {interpretationModel} from "../../content";



const InterpretationModel = () => {


    return (
        <section className="interpretation-model page">
          <div className="content-wrapper">
              <Backbutton linkUrl="/folelser" title="Følelser"    />
              <h1>{interpretationModel.title}</h1>
              <PageIntro soundfile={interpretationModel.pageIntroAudio}>
                  <p>{interpretationModel.pageIntro}</p>
              </PageIntro>
              <div className="imageWrapper">
                  <img src="/images/misc/tolkningsmodellen.png" alt=""/>
              </div>
              <h2 className="sound-heading">Slik bruker du tolkningsmodellen</h2>
              <Collapse listItems={interpretationModel.collapseContent} soundfile={interpretationModel.audioManuscript} />


          </div>
        </section>
    )
}

export default InterpretationModel;
