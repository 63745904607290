import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import Collapse from "../page-content/Collapse-content"


import {thoughtScale} from "../../content";



const ThoughtScale = () => {

    return (
        <section className="thought-scale page">
            <div className="content-wrapper">
                <Backbutton linkUrl="/folelser" title="Følelser"    />
                <h1>{thoughtScale.title}</h1>
                <PageIntro soundfile={thoughtScale.pageIntroAudio}>
                    <p>{thoughtScale.pageIntro}</p>
                </PageIntro>
                <div className="imageWrapper">
                    <img src="/images/misc/tankeskalaen.png" alt=""/>
                </div>
                <h2 className="sound-heading">Slik bruker du tankeskalaen</h2>
                <Collapse listItems={thoughtScale.collapseContent} soundfile={thoughtScale.audioManuscript} />
            </div>
        </section>
    )
}

export default ThoughtScale;
