import React from "react";
import "./Sub-nav.sass";
import { Link } from "react-router";

//TODO FIX FIREFOX MASK ISSUE
const SubNav = ({ navItems }) => {
  const listItem = navItems.map((item, index) => (
    <li key={index}>
      <Link
        style={{
          MozMaskImage: `url(/images/nav-bg.svg)`,
          WebkitMaskImage: `url(/images/misc/nav-bg.svg)`,
        }}
        to={item.path}
      >
        <span>{item.name}</span>
      </Link>
    </li>
  ));

  return (
    <section className="sub-nav">
      <ul>{listItem}</ul>
    </section>
  );
};

export default SubNav;
