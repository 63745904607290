import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import Collapse from "../page-content/Collapse-content"


import {stressScale} from "../../content";



const StressScale = () => {


    return (
        <section className="stress-scale page">
            <div className="content-wrapper">
                <Backbutton linkUrl="/folelser" title="Følelser"    />
                <h1>{stressScale.title}</h1>
                <PageIntro soundfile={stressScale.pageIntroAudio}>
                    <p>{stressScale.pageIntro}</p>
                </PageIntro>
                <div className="imageWrapper">
                    <img src="/images/misc/stressskalaen.png" alt=""/>
                </div>
                <h2 className="sound-heading">Slik bruker du stresskalaen</h2>
                <Collapse listItems={stressScale.collapseContent} soundfile={stressScale.audioManuscript} />
            </div>
        </section>
    )
}

export default StressScale;
