import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"


import {thoughtLearnings} from "../../content";

const ThoughtLearnings = () => {

    return (
        <section className="thought-learning page">
            <div className="content-wrapper">
                <Backbutton linkUrl="/laerende-tankesett" title="Lærende tankesett"/>
                <h1>{thoughtLearnings.title}</h1>
                <PageIntro soundfile={thoughtLearnings.pageIntroAudio}>
                    <p>{thoughtLearnings.pageIntro}</p>
                </PageIntro>
                <div className="videoWrapper">
                    <iframe title="vimeo-player" style={{width: '100%'}} src="https://player.vimeo.com/video/497919905"
                            width="640" height="360" frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                </div>
            </div>
        </section>
    )
}

export default ThoughtLearnings;
