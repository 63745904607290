import React from 'react';
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro"
import Collapse from "../page-content/Collapse-content"
import MindMap from "../mindmap/MindMap"

import {relationMap} from "../../content";




const RelationMap = () => {

    return (
        <section className="relation-map page">
            <div className="content-wrapper">
                <Backbutton linkUrl="/sosiale-relasjoner" title="Sosiale relasjoner"    />
                <h1>{relationMap.title}</h1>
                <PageIntro soundfile={relationMap.pageIntroAudio}>
                    <p>{relationMap.pageIntro}</p>
                </PageIntro>
                <h2 className="sound-heading">Slik bruker du relasjonskartet</h2>
                <Collapse listItems={relationMap.collapseContent} soundfile={relationMap.audioManuscript}>
                </Collapse>

                <MindMap/>
            </div>
        </section>
    )
}

export default RelationMap
