import {ReactComponent as HuskelisteSamtale} from "./images/misc/huskeliste-starte-samtale.svg";
import {ReactComponent as HuskelisteTaVarePaaHverandre} from "./images/misc/huskeliste-ta-vare-pa-hverandre.svg";
import {ReactComponent as HuskelisteTaLaerendeTankesett} from "./images/misc/huskeliste-for-laerende-tankesett.svg";

export const passAuth = {
    phrase : 'sel-i-skolen',
    ttl : 'none'
}

export const frontpage = {
        title: 'Forside',
        subtitle: '',
        textContent: [
            'ROBUST er et undervisningsopplegg for elever på ungdomstrinnet. ',
            'Målet med ROBUST er å øke trivsel og motivasjon gjennom å lære gode verktøy for å takle utfordringer som oppstår i og utenfor skolehverdagen.',

        ]
}
export const socialRelations = {
    pageIntro: 'I temaet Sosiale relasjoner vektlegges måter å oppnå kontakt, få og gi støtte og vennskap. Gå inn på verktøyet du vil se nærmere på.',
    pageIntroAudio:'/audio/03_Ledesteg_Sosiale_relasjoner.mp3',
    navText: [
        {
            name: 'Relasjonskart',
            path:'/relasjonskart'
        },
        {
            name: 'Huskeliste for å starte en samtale',
            path:'/huskeliste-for-aa-starte-en-samtale'
        },
        {
            name: 'Ta vare på hverandre-plakaten',
            path:'/ta-vare-paa-hverandre-plakaten'
        },

    ]
}

export const relationMap = {
    title: 'Relasjonskart',
    pageIntro: 'Noen ganger kan du trenge hjelp eller støtte fra andre når du står ovenfor et problem eller en utfordring. Relasjonskartet hjelper deg med å få en oversikt over hvem du kan be om hjelp eller støtte, og hva du tror de kan hjelpe deg med.',
    pageIntroAudio:'/audio/04_Ledesteg_Relasjonskart.mp3',
    audioManuscript : '/audio/05_Manus_relasjonsskart_2.mp3',
    collapseContent:[
        'Se for deg at du står ovenfor et problem, et tenkt problem eller et du henter fra virkeligheten. Det trenger ikke være så veldig alvorlig, men noe du kan trenge hjelp eller støtte for å løse. Hvem kan du søke støtte og hjelp hos? Hva kan hjelpen bestå av?',
        'Skriv navnet ditt inn i relasjonskartet. På linjene ut av kartet skriver du inn hvem som kan hjelpe deg, og hva de kan hjelpe deg med. Det kan være personer du har nære relasjoner til og ikke fullt så nære relasjoner til. Tenk gjennom hvem som kan gi deg praktisk støtte, og hva denne støtten kan bestå i. ',
        'Praktisk støtte handler om å få konkret hjelp, informasjon, råd og veiledning. Et eksempel kan være et eldre søsken som kan hjelpe deg med en vanskelig oppgave i et skolefag. ',
        'Tenk også gjennom hvem som kan gi deg følelsesmessig eller emosjonell støtte og hva denne støtten kan bestå i. Følelsesmessig støtte handler om hjelp til å forstå situasjoner på en god måte. Det handler om å få hjelp til å bearbeide opplevelser, omsorg, kjærlighet, oppmuntring og empati, og om å få trøst når ting ikke går som du ønsker eller noe oppleves vanskelig. Et eksempel kan være en god venn å prate med når du føler deg nedfor. ',
        'Personer i nettverket ditt kan være både nære og gode, eller ikke fullt så nære. Eksempler kan være familie, lærere, trenere, helsesykepleier, psykolog, eller klassekamerater. ',
        'Husk at du noen ganger kan oppleve å ikke ha så mange støttende relasjoner rundt deg, mens du andre ganger opplever å ha flere. Når du har få, kan du jobbe for å få inn flere personer i relasjonskartet ditt. ',
        'Noen ganger er relasjonskartet mer familieorientert, mens det andre ganger er mer venneorientert. ',
        'Når du har fått en oversikt over nettverket ditt slik du har nå, kan du vurdere om du vil endre på noe med kartet og tenke gjennom hvordan du kan gjøre det.',
        'Husk at målet ikke er å ha det største nettverket, men å vite om ditt nettverk og hvem du kan søke støtte hos når du trenger det. ',
        'Ettersom relasjonene dine med andre utvikles og forandres, vil også relasjonskartet kunne endres. Det kan derfor være lurt å fylle ut relasjonskartet med jevne mellomrom.',
    ]
}

export const conversationList = {
    title: 'Huskeliste for å starte en samtale',
    pageIntro: 'Av og til kan det oppleves vanskelig å ta kontakt med noen. Da kan det være nyttig å tenke litt gjennom hvordan du kan ta kontakt med andre.',
    pageIntroAudio:'/audio/06_Ledesteg huskeliste for a starte en samtale.mp3',
    image: '/images/misc/huskeliste-starte-samtale.svg',
    svg: <HuskelisteSamtale/>,
    listAudio:'/audio/07_Manus huskeliste for a starte en samtale.mp3',
    list:[
        'Tenk over hvordan du vil starte samtalen. Si gjerne navnet ditt og hvem du er dersom du snakker med noen du ikke kjenner.',
        'Still deg i passe avstand til den du vil snakke med. Ha øyekontakt.',
        'Still spørsmål til den andre, og svar på spørsmål som stilles deg. Vis interesse for det den andre sier. Forsøk å forstå den andres perspektiv. Lytt til det den andre sier. Snakk etter tur.',
        'Prøv å unngå misforståelser gjennom måten du sier noe på ved å være tydelig og ved å spørre dersom du er usikker på hva den andre mener.',
        'Vær bevisst kroppsspråket ditt. Stå vendt mot den du snakker med. Smil litt.',
    ]
}

export const careList = {
    title: 'Ta vare på hverandre-plakaten',
    pageIntro: 'Når du er med andre er det viktig at dere tar vare på hverandre under samtalen. Da tar dere sosialt ansvar for hverandre. Her får du noen stikkord over hva det kan være lurt å gjøre når du skal ta sosialt ansvar.',
    pageIntroAudio:'/audio/08_Ledesteg ta vare pa hverandre-plakaten.mp3',
    image: '/images/misc/huskeliste-ta-vare-pa-hverandre.svg',
    svg: <HuskelisteTaVarePaaHverandre />,
    listAudio:'/audio/09_Manus ta vare pa hverandre-plakaten.mp3',
    list: [
        'Å ta vare på hverandre handler om å være oppmerksom både på hva du sier og hvordan du står når du snakker med andre',
        'Snakk om andre på en positiv måte',
        'Pass på at alle er inkludert i samtalen',
        'Inkluder andre også gjennom måten dere står på og inkluder andre i skolearbeidet',
        'Gi hjelp når andre spør om det',
    ]
}

export const attentionTraining = {
    pageIntro: 'Her finner du ulike øvelser i oppmerksomhetstrening. Finn den øvelsen du vil gjøre og trykk på spill lyd.',
    pageIntroAudio:'/audio/10_Ledesteg_Oppmerksomhetstrening.mp3',
    excersises: [
        {title:'Pusteanker ved nese', image:'/images/breathing/pusteanker.svg', audio:'/audio/breathing/Pusteanker ved nese.mp3'},
        {title:'Pusteanker ved mage', image:'/images/breathing/pusteanker-mage.svg', audio:'/audio/breathing/Pusteanker ved mage.mp3'},
        {title:'Å være oppmerksom med tankene', image:'/images/breathing/tankene.svg', audio:'/audio/breathing/Oppmerksom Tankene.mp3'},
        {title:'SOAL', image:'/images/breathing/soal.svg', audio:'/audio/breathing/SOAL.mp3'},
        {title:'Å telle pusten', image:'/images/breathing/telle.svg', audio:'/audio/breathing/A telle pusten.mp3'},
        {title:'Rullegardinøvelse', image:'/images/breathing/rullegardin.svg', audio:'/audio/breathing/Rullegardin.mp3',heading:'Bonusmateriell'},
        {title:'Pustepause', image:'/images/breathing/pustepause.svg', audio:'/audio/breathing/Pustepause.mp3',heading:'Bonusmateriell'},
    ]
}

export const problemSolving = {
    pageIntro: 'Problemløsing handler om å nærme seg utfordringer og problem på en systematisk og gjennomtenkt måte. Gå inn på modellen du vil se nærmere på.',
    pageIntroAudio:'/audio/11_Ledesteg_Problemlosning.mp3',
    navText: [
        {name:'Tretrinnsmodellen', path:'/tretrinnsmodellen'},
        {name:'Stigemodellen',path:'/stige-modellen'},
    ]
}

export const threeStepModel = {
    title: 'Tretrinnsmodellen',
    pageIntro: 'Tretrinnsmodellen kan hjelpe deg med å løse forskjellige problemer eller utfordringer du møter. Det kan være ting på skolen eller på fritiden, med venner eller med familie. Tretrinnsmodellen består av tre trinn: Stopp, tenk, gjør.',
    pageIntroAudio:'/audio/12_Ledesteg tretrinns-modellen.mp3',
    audioManuscript : '/audio/13_Manus tre trinns-modellen.mp3',
    collapseContent: [
        'Se for deg at du står ovenfor en utfordring eller et problem, enten tenkt eller fra virkeligheten. Det trenger ikke være noe alvorlig, men en utfordring som du trenger å tenke litt gjennom for å klare å løse.',
        'Et eksempel kan være at du skal ha en presentasjon på skolen som du gruer deg litt til. Hvordan kan du nærme deg denne utfordringen?',
        'En måte er å bruke tretrinnsmodellen. Du kan jobbe deg gjennom utfordringen eller problemet du står ovenfor på følgende måte:',
        'STOPP: ',
        'reflekter først over hva problemet egentlig består av. Hvilket problem skal du løse? I eksempelet med presentasjon på skolen vil det kunne være å klare å gjennomføre presentasjonen på best mulig måte.',
        'TENK: ',
        'Tenk godt gjennom ulike valg du kan ta for å løse problemet, ulike måter du kan takle problemet på. I eksempelet med presentasjonen kan det være å forberede deg ved å lese godt om temaet du skal presentere og hvilken måte du skal presentere på. Hvor kan du finne ut mer om temaet? Hvilke kilder er gode å benytte om tematikken? Vil du holde en digital presentasjon, lage en plakat, eller bare ha en muntlig fremføring? ',
        'Tenk deretter gjennom hvilke konsekvenser hvert av disse valgene kan ha. Hvilken informasjon vil de ulike kildene kunne gi deg? Hvilken form for presentasjon vil kunne gi best læringsutbytte for deg og dine medelever, og hvilken passer best til tematikken?',
        'GJØR: ',
        'Ta et valg, ta det valget du tenker er best basert på problemet du står ovenfor og konsekvensene du har vurdert. La oss si at du valgte å lage en plakat som du presenterer foran klassen. Du har valgt å benytte deg av den digitale versjonen av Store Norske leksikon og læreboken i faget for å finne mest mulig informasjon om tematikken. Gjennomfør nå det du har valgt så godt du kan.',
        'I eksempelet her vil det tilsi å være kildekritisk særlig til informasjon fra internett og samkjøre informasjonen fra leksikonet og læreboken på en måte som gjør at de utfyller hverandre. Du lager mindre tekster og tegner eller limer på bilder om tema. ',
        'Før presentasjonen øver du hjemme. Vurder resultatet etterpå. Fungerte det? Hva kunne vært gjort bedre eller annerledes? Ville du valgt det samme igjen? Hvorfor eller hvorfor ikke?',
        'I eksempelet med å holde en presentasjon vil en slik vurdering kunne bestå av å tenke gjennom om du opplevde presentasjonen som ok å gjennomføre, om du klarte å presentere det du ønsket, om du neste gang vil presentere på samme måte, og om det hjalp å øve på å holde presentasjonen i forkant.',
        'Forsøk å møte utfordringer eller problemer med tretrinnsmodellen i tiden fremover. Det vil gi deg muligheten til å ta best mulig valg og lære av valgene du tar slik at du kan bli best mulig på å takle utfordringer.',
    ]
}

export const ladderModel = {
    title: 'Stigemodellen',
    pageIntro: 'Av og til kan det oppleves utfordrende å få til noe du har lyst til. Da kan det være lurt å dele målet ditt opp i mindre deler. Stigemodellen kan hjelpe deg med det.',
    pageIntroAudio:'/audio/14_Ledesteg stigemodellen.mp3',
    audioManuscript : '/audio/15_Manus stige-modellen.mp3',
    collapseContent: [
        'Tenk på noe du har lyst til å få til eller bli bedre på. Det kan være å bli bedre i et fag, bli kjent med noen eller bli bedre i en type sport. Her bruker vi eksempelet om å bli bedre i norsk.',
        'For å få en oversikt over hva som må til for at du skal kunne nå dette målet kan du bruke stigemodellen. Stigemodellen kan hjelpe deg med å nærme deg et problem og finne ut hvilke steg du må ta, ett om gangen, for å komme til målet ditt.',
        'Se for deg at du skriver målet øverst på stigen: «jeg vil bli bedre i norsk». På bunnen av stigen ser du for deg at du skriver hvor du er nå, det som er utgangspunktet ditt. Her kan du for eksempel skrive at «jeg er middels god i norsk».',
        'Tenk nå gjennom hva du må kunne for å nå målet ditt. Forsøk å dele dette opp i flere mindre deler, slik at du kan sette en ting på hvert steg av stigen. Det kan for eksempel være å snakke med lærer om hva du kan arbeide med for å bli bedre, øve mer i forkant av vurderingssituasjoner, og samarbeide med medelever for å forstå mer av faget.',
        'Hver ting du tenker er nødvendig for å nå målet ditt, er ett steg i stigen. Ranger stegene, fra lettest til vanskeligere. Lettest er nærmest bunnen av stigen, mens stegene blir litt mer utfordrende for hvert steg du tar oppover.',
        'Du kan for eksempel begynne med å snakke med lærer, fortsette med å samarbeide med medelever, og ha øving i forkant av vurderinger høyere oppe. Det er opptil deg hvilke steg du plasserer hvor.',
        'Det kan også være at stigen din er kortere enn den på bildet, eller den er lengre. Se for deg at du legger til steg eller fjerner steg slik at stigen passer til ditt mål akkurat nå. Hvis ditt mål er å bli bedre i norsk, eller et annet fag, hvilke steg vil du sette inn i stigen?',
        'Se for deg at du tar ett steg om gangen oppover stigen. Det er ikke lurt å ta for store skritt om gangen.',
        'Forsøk å bruke stigemodellen når du har mål som det kan være lurt at du deler opp i mindre deler. Jobb deg oppover stigen, ett steg om gangen. Husk at stegene må være realistiske og tilpasset hver enkelt utfordring.',
    ]
}

export const feelings = {
    pageIntro: 'I temaet Følelser vektlegges måter for å forstå og regulere eller justere følelser. Gå inn på verktøyet du vil se nærmere på.',
    pageIntroAudio:'/audio/16_temaet Folelser.mp3',
    navText: [
        {name:'Tankeskalaen',path:'/tenkeskalaen'},
        {name:'Stresskalaen',path:'/stressskalaen'},
        {name:'Tolkningsmodellen',path:'/tolkningsmodellen'},
    ]
}

export const stressScale = {
    title: 'Stresskalaen',
    pageIntro: 'Noen ganger kan du oppleve stress som overveldende og vanskelig å forholde deg til. Det kan også være at du stresser unødvendig mye over noen oppgaver. Da kan det være nyttig å sette stresset i perspektiv.',
    pageIntroAudio:'/audio/17_Ledesteg til stresskalaen.mp3',
    audioManuscript: '/audio/18_Manus stresskalaen.mp3',
    collapseContent: [
        'Se for deg at du forbereder deg til en viktig prøve. Hvor stresset føler du deg?',
        'For å finne ut av hvor stresset du føler deg kan du sette kryss på stresskalaen. Skalaen går fra 0-100 hvor 0 er ingenting stress og 100 er maksimalt stress. Se for deg at du setter et kryss på stresskalaen for å markere stresset du kjenner på når du tenker på en viktig prøve.',
        'Etter du har satt et tenkt kryss på skalaen for prøven, ser du for deg at en person du er glad i blir syk eller utsatt for noe annet alvorlig. Hvor vil du sette et kryss for å markere stresset du kjenner på nå?',
        'Det er sannsynlig at det er vanskelig for deg å sette krysset på samme linjen som det første krysset, for det er viktigere med en person du er glad i enn en skoleprøve. Samtidig kan det oppleves som om du er veldig stresset over skolerelaterte prestasjoner, faktisk så mye at det kan føles litt for mye.',
        'Neste gang du opplever å bli stresset over skolearbeid, forsøk å sette stresset i perspektiv ved å tenke gjennom andre, og kanskje viktigere ting, i livet. Hva er de viktige tingene i livet? Gode venner? Gode stunder med familien? Gode skoleprestasjoner? Å gjøre så godt du kan på skolen? Å være, og å ha, en god venn?',
        'Kan det tenkes at du stresser mer enn nødvendig? Hva kan eventuelt det komme av?',
        'Etter å ha tenkt gjennom slike spørsmål kan det hende du opplever at stresset tilknyttet skolen blir litt mindre, men at du samtidig klarer å gjøre ditt beste med oppgaven eller prøven du har foran deg.',
        'Du har nå satt stresset i perspektiv.',
    ]
}

export const thoughtScale = {
    title: 'Tankeskalaen',
    pageIntro: 'Tankene påvirker følelsene og handlingene dine. Det kan derfor være lurt å vurdere hvor hjelpsom en tanke er fordi du da lettere kan finne ut om det er en mindre eller en mer hjelpsom tanke. For å vurdere hvor hjelpsom en tanke er, kan du gradere den i tankeskalaen.',
    pageIntroAudio:'/audio/19_Ledesteg tankeskalaen.mp3',
    audioManuscript: '/audio/20_Manus tankeskalaen.mp3',
    collapseContent: [
        'Tenk deg at du sitter i klasserommet og får beskjed om at du neste uke skal presentere en oppgave du har arbeidet med sist uke. Hvordan tenker du om denne presentasjonen? Tenker du at «jeg kommer aldri til å klare det» eller «det skal bli fint å få legge frem arbeidet mitt»? Eller noe midt i mellom?',
        'Tankeskalaen går fra 0-10. For å gradere, stiller du følgende spørsmål: Hvor hjelpsom er tanken min på en skala fra 0-10?',
        'Hvis tanken er helt oppe på 10, så er den veldig hjelpsom. I eksempelet med presentasjonen, vil tanken «det skal bli fint å få legge frem arbeidet mitt» være en hjelpsom tanke. Da kan du fortsette å ha tanken uten å gjøre noe med den.',
        'Hvis den er langt nede mot 0, så er den lite hjelpsom. Tanken «jeg kommer aldri til å klare det» om presentasjonen er en lite hjelpsom tanke. ',
        'Mindre hjelpsomme tanker kan du forsøke å bytte ut med mer hjelpsomme tanker. I eksempelet med presentasjonen kan du forsøke å bytte ut tanken «jeg kommer aldri til å klare det» med tanken «hvis jeg arbeider godt med presentasjonen nå, vil jeg klare å gjennomføre presentasjonen så godt jeg kan».',
        'Hvis tanken er på 5, er tanken passe hjelpsom og du kan vurdere å bytte den ut med mer hjelpsomme tanker dersom du føler behov for det. ',
        'I eksempelet med presentasjonen kan en passe hjelpsom tanke være «greit nok å bli ferdig med presentasjonen». ',
        'Det er ikke alltid lett å bytte ut tanker, men tankeskalaen er et viktig første steg i å finne ut av hvor hjelpsomme tankene dine faktisk er før du eventuelt bytter ut tanken med en mer hjelpsom tanke.',
    ]
}

export const interpretationModel = {
    title: 'Tolkningsmodellen',
    pageIntro: 'Din tolkning er viktig for hvordan du oppfatter en situasjon, og om du blir mer eller mindre stresset av den utfordringen du står ovenfor. Tolkningene dine påvirker følelser og derfor også handlinger.',
    pageIntroAudio:'/audio/21_Ledesteg tolkningsmodellen.mp3',
    audioManuscript: '/audio/22_Manus tolkningsmodellen.mp3',
    collapseContent: [
        'Se for deg følgende: Du ser en kamerat eller venninne på andre siden av gaten. Han eller hun går med flere andre venner. Du vinker og smiler, men får ingen respons hos den andre. Hvorfor ikke?',
        'Gå nå gjennom følgende steg: Hvordan tolker du at vennen din ikke vinker tilbake til deg? Hva er dine tanker og meninger rundt at du blir oversett?',
        'Eksempler på tolkninger kan være at han eller hun er sur på deg fordi du har gjort noe galt, eller at han eller hun faktisk ikke så deg fordi hodet var fullt av andre tanker. Gitt denne tolkningen, hvilken følelse vil du kunne få av en slik tolkning?',
        'Dersom tolkningen din er at vennen din er sur på deg kan du bli bekymret og frustrert. Dersom tolkningen din er at vennen din ikke så deg, er du mer likegyldig. Kanskje synes du til og med at det var litt morsomt?',
        'Gitt tolkningen din og følelsen som vil oppstå av denne, hvilken handlingstrang vil du kunne få? Dersom du tenker at vennen din er sur på deg eller du har gjort noe galt, kan du begynne å gruble og kanskje unngå vennen din i tiden etterpå.',
        'Dersom du tenker at vennen din ikke så deg, kan det medføre at du vinker ekstra eller roper navnet på vennen din. Så gjenstår hvordan du faktisk handler, hva du gjør.',
        'Det kan være at dersom du tenker at vennen din ikke ser deg eller er sur på deg, så grubler du litt over dette og spør vennen din om dette neste gang dere treffes. Dersom du tenker at vennen din ikke så deg, kan det være du fleiper med dette neste gang dere treffes. Men ser du at måten du tenker om situasjonen på, påvirker hvordan du har det, hva du har lyst til å gjøre og hva du faktisk gjør i en situasjon?',
        'I situasjoner hvor du begynner med negativ tenkning og du ikke har tilgang på fasiten (som i eksempelet over ville være å spørre kameraten eller venninnen hvorfor han eller hun ikke hilste tilbake) så vil du ha det bedre dersom du velger den tolkningen som er best for deg. Det er det samme for andre hvilken tolkning du velger der og da, så hvorfor ikke velge den som gjør dagen og livet lettere?',
        'I hvert fall inntil du har tilgang på en form for «fasit». Neste gang du merker at du tolker en hendelse negativt, forsøk å utfordre din egen tolkning gjennom å lete etter alternative måter å tolke på, og vurder hvilken tolkning du velger å leve etter, i hvert fall inntil du har tilgang på en slags fasit.',

    ]
}

export const learningMindset = {
    pageIntro: 'Lærende tankesett viser til en måte å tenke om krevende læring. Å ha et lærende tankesett kan gjøre det lettere å ta fatt på oppgaver du står ovenfor. Gå inn på verktøyet du vil se nærmere på.',
    pageIntroAudio:'/audio/23_Laerende tankesett.mp3',
    navText: [
        {name:'Hvordan tenke rundt læring?',path:'/hvordan-tenke-rundt-laering'},
        {name:'Huskeliste for lærende tankesett',path:'/huskeliste-for-laerende-tankesett'},

    ]
}

export const thoughtLearnings = {
    title: 'Hvordan tenke rundt læring?',
    pageIntroAudio:'/audio/24_Ledesteg steg animasjonsfilm tilknyttet tanker om laering.mp3',
    pageIntro: 'Av og til oppleves faglige utfordringer som vanskelige. Da kan det være nyttig å reflektere over hvordan du kan tenke om krevende læring. Se filmen for å finne ut mer.'
}

export const learningList = {
    title: 'Huskeliste for lærende tankesett',
    pageIntro: 'Noen ganger kan oppgaver føles vanskelige og du kan miste motivasjonen for å jobbe med dem. Da kan det være lurt å tenke over hvordan du kan klare det gjennom et lærende tankesett.',
    pageIntroAudio:'/audio/25_Ledesteg huskeliste for laerende tankesett.mp3',
    image: '/images/misc/huskeliste-for-laerende-tankesett.svg',
    svg: <HuskelisteTaLaerendeTankesett />,
    listAudio:'/audio/26_Manus huskeliste for laerende tankesett.mp3',
    list: [
        'Det er viktig at du prøver deg på oppgaver du opplever som utfordrende',
        'Når du utfordrer deg selv, lærer du mer og hjernen blir sterkere og smartere',
        'For å klare å løse utfordrende oppgaver kan det hjelpe å prøve ulike fremgangsmåter og strategier',
        'Selv om noe er vanskelig er det viktig å ikke gi opp',
        'Våg å gjøre feil - å gjøre feil er en viktig kilde til læring',
        'Dersom du fremdeles ikke får det til - be om hjelp',
    ]
}

export const atlars = {
    pageIntro: 'Musikeren Atlars har laget musikkvideo til ROBUST og flere sanger som brukes i ROBUST. Finn den sangen du vil høre, og spill lyd. ',
    pageIntroAudio:'/audio/27_Ledesteg til Atlars.mp3',
    audioTracks: [
        {title:'ROBUST Anthem\nSterkere',audio:'/audio/atlars/06_robust.mp3'},
        {title:'Takk for at du e deg',audio:'/audio/atlars/01_takk_for_at_du_e_deg.mp3'},
        {title:'Rotteracet',audio:'/audio/atlars/03_rotteracet.mp3'},
        {title:'Ting tar tid',audio:'/audio/atlars/05_ting_tar_tid.mp3'},
        {title:'Det ordne seg',audio:'/audio/atlars/02_det_ordne_seg.mp3'},
        {title:'Sagaen',audio:'/audio/atlars/04_sagaen.mp3'},
    ]
}
