import React from "react";
import "./Problemsolving.sass";
import Backbutton from "../navigation/Backbutton";
import PageIntro from "../page-content/Page-intro";
import SubNav from "../page-content/Sub-nav";
import { ReactComponent as Problemlosning } from "../../images/categories/problemlosning.svg";
import { problemSolving } from "../../content";

const Problemsolving = () => (
  <section className="problem-solving page">
    <div className="content-wrapper">
      <Backbutton linkUrl="/valg" title="Forsiden" />
      <div className="top-image-wrapper">
        <Problemlosning />
      </div>
      <PageIntro soundfile={problemSolving.pageIntroAudio}>
        <p>{problemSolving.pageIntro}</p>
      </PageIntro>
      <SubNav navItems={problemSolving.navText} />
    </div>
  </section>
);

export default Problemsolving;
