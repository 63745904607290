import { useState, useEffect, useContext } from "react";
import { AudioContext } from "../../../App";
import { useLocation } from "react-router";

const usePlaying = (content) => {
  const location = useLocation();
  const audioContext = useContext(AudioContext);
  const [playing, setPlaying] = useState(false);

  const _setPlaying = (isPlaying) => {
    if (
      (isPlaying && !audioContext.audio) ||
      (isPlaying && audioContext.url !== content.audio)
    ) {
      if (audioContext.url !== content.audio && audioContext.audio) {
        audioContext.audio.pause();
        audioContext.stop();
        audioContext.audio.removeEventListener("ended", audioContext.stop);
      }

      audioContext.location = location.pathname;
      audioContext.audio = new Audio(content.audio);
      audioContext.url = content.audio;
      audioContext.stop = () => _setPlaying(false);
    }

    if (isPlaying) {
      audioContext.audio.play();
    } else {
      audioContext.audio.pause();
    }

    setPlaying(isPlaying);
  };

  useEffect(() => {
    if (!audioContext.audio) return;
    if (audioContext.url === content.audio) {
      playing ? audioContext.audio.play() : audioContext.audio.pause();
    }
  }, [playing, audioContext.audio, audioContext.url, content.audio]);

  useEffect(() => {
    if (!audioContext.audio) return;
    audioContext.audio.addEventListener("ended", audioContext.stop);
    return () => {
      if (audioContext.url === content.audio) {
        audioContext.audio.removeEventListener("ended", audioContext.stop);
      }
    };
  }, [audioContext.audio, audioContext.stop, audioContext.url, content.audio]);

  return { isPlaying: playing, setPlaying: _setPlaying };
};

export default usePlaying;
