import React from "react";
import "./Atlars-music.sass";
import usePlaying from "./hooks/usePlaying";

const AtarsMusic = ({ content }) => {
  const { isPlaying, setPlaying } = usePlaying(content);

  return (
    <section className="atlars-track">
      <div
        style={{
          WebkitMaskImage: `url(/images/misc/nav-bg.svg)`,
          maskImage: `url(../../images/nav-bg.svg)`,
        }}
        className="music-title"
      >
        {content.title.split("\n").map((component) => (
          <span key={component}>{component}</span>
        ))}
      </div>

      <div onClick={() => setPlaying(!isPlaying)} className="control-wrapper">
        <svg
          width="77"
          height="62"
          viewBox="0 0 77 62"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {!isPlaying && (
            <>
              <path
                d="M75.9422 30.4573C75.4519 21.7292 76.1476 9.26342 66.5031 3.20253C62.9425 0.964031 56.8406 -0.255334 52.3174 0.0450149C49.3806 0.240113 44.9011 1.11036 42.1851 1.88561C35.1692 3.89308 31.601 11.6251 30.3329 16.3819C28.2484 24.1885 30.0941 32.3646 33.226 39.5704C35.621 45.0871 39.5359 46.5965 46.8674 48.1085C51.9682 49.1585 57.9752 50.2675 63.1428 48.3396C71.1007 45.3695 76.3504 38.4871 76.1784 32.0668L75.9422 30.4573Z"
                fill="#A5C74C"
              />
              <path
                d="M37.6725 17.5345C36.2914 23.9522 34.3866 33.5172 40.1754 39.4959C42.3138 41.7036 45.3095 42.4943 48.6468 43.0205C50.8159 43.3645 54.166 44.6198 56.2915 44.5043C61.7877 44.2014 66.0927 38.4742 67.9589 35.232C71.024 29.913 71.2961 23.6801 70.4721 17.9375C69.8252 13.5401 67.2915 11.8099 62.2728 9.49951C58.7867 7.90535 54.6435 6.11353 50.513 6.67572C44.1441 7.52286 38.9611 11.6559 37.8188 16.3408L37.6725 17.5345Z"
                fill="white"
              />
              <path
                d="M46.2046 9.65881V39.6988L68.5408 25.0639L46.2046 9.65881Z"
                fill="#59BA58"
              />
              <path
                d="M11.0385 61.6113C11.0385 61.6113 20.3544 52.4519 21.3915 51.6741C22.4286 50.8963 23.073 50.904 24.5644 51.0837C26.0559 51.2634 35.5131 49.0557 37.8414 47.6258C40.1697 46.196 43.2605 45.5876 42.7189 42.9358C42.3492 41.1029 38.6115 42.0527 35.6902 42.3813C32.7688 42.7099 30.7023 43.2335 30.7665 42.9075C30.8307 42.5815 45.9919 31.2375 49.0647 28.4856C52.1375 25.7337 56.2192 22.9228 54.5634 20.6201C53.208 18.7384 49.6423 20.158 45.4554 23.1872C41.2685 26.2163 33.9035 32.0205 33.9035 32.0205C33.9035 32.0205 31.6701 29.1582 28.4921 29.0838C28.202 29.0838 23.3245 25.3589 23.3245 25.3589C23.3245 25.3589 18.3752 23.3053 16.6938 25.2562C15.0123 27.2072 11.2695 31.7048 10.8844 34.0382C10.4994 36.3717 10.1143 40.7512 9.53415 41.3365C8.95399 41.9218 0 52.1131 0 52.1131L11.0385 61.6113Z"
                fill="#F7B1C7"
              />
            </>
          )}
          {isPlaying && (
            <>
              <path
                d="M75.9422 30.4573C75.4519 21.7292 76.1476 9.26342 66.5031 3.20253C62.9425 0.964031 56.8406 -0.255334 52.3174 0.0450149C49.3806 0.240113 44.9011 1.11036 42.1851 1.88561C35.1692 3.89308 31.601 11.6251 30.3329 16.3819C28.2484 24.1885 30.0941 32.3646 33.226 39.5704C35.621 45.0871 39.5359 46.5965 46.8674 48.1085C51.9682 49.1585 57.9752 50.2675 63.1428 48.3396C71.1007 45.3695 76.3504 38.4871 76.1784 32.0668L75.9422 30.4573Z"
                fill="#C6D98E"
              />
              <path
                d="M37.6725 17.5345C36.2914 23.9522 34.3866 33.5172 40.1754 39.4959C42.3138 41.7036 45.3095 42.4943 48.6468 43.0205C50.8159 43.3645 54.166 44.6198 56.2915 44.5043C61.7877 44.2014 66.0927 38.4742 67.9589 35.232C71.024 29.913 71.2961 23.6801 70.4721 17.9375C69.8252 13.5401 67.2915 11.8099 62.2728 9.49951C58.7867 7.90535 54.6435 6.11353 50.513 6.67572C44.1441 7.52286 38.9611 11.6559 37.8188 16.3408L37.6725 17.5345Z"
                fill="white"
              />
              <path d="M44 12V37H51V12H44Z" fill="#59BA58" />
              <path d="M57 12V37H64V12H57Z" fill="#59BA58" />
              <path
                d="M11.0385 61.6113C11.0385 61.6113 20.3544 52.4519 21.3915 51.6741C22.4286 50.8963 23.073 50.904 24.5644 51.0837C26.0559 51.2634 35.5131 49.0557 37.8414 47.6258C40.1697 46.196 43.2605 45.5876 42.7189 42.9358C42.3492 41.1029 38.6115 42.0527 35.6902 42.3813C32.7688 42.7099 30.7023 43.2335 30.7665 42.9075C30.8307 42.5815 45.9919 31.2375 49.0647 28.4856C52.1375 25.7337 56.2192 22.9228 54.5634 20.6201C53.208 18.7384 49.6423 20.158 45.4554 23.1872C41.2685 26.2163 33.9035 32.0205 33.9035 32.0205C33.9035 32.0205 31.6701 29.1582 28.4921 29.0838C28.202 29.0838 23.3245 25.3589 23.3245 25.3589C23.3245 25.3589 18.3752 23.3053 16.6938 25.2562C15.0123 27.2072 11.2695 31.7048 10.8844 34.0382C10.4994 36.3717 10.1143 40.7512 9.53415 41.3365C8.95399 41.9218 0 52.1131 0 52.1131L11.0385 61.6113Z"
                fill="#F7B1C7"
              />
            </>
          )}
        </svg>
      </div>
    </section>
  );
};

export default AtarsMusic;
