import "./index.sass";
import "./App.sass";
import Home from "./components/general/Home";
import NavScreen from "./components/general/Nav-screen";
import SocialRelations from "./components/general/Social-relations";
import Feelings from "./components/general/Feelings";
import LearningMindset from "./components/general/Learning-mindset";
import Problemsolving from "./components/general/Problemsolving";
import AttentionTraining from "./components/general/Attention-training";
import Atlars from "./components/general/Atlars";
import RelationMap from "./components/sub-pages/Relation-map";
import ConversationList from "./components/sub-pages/Conversation-list";
import ThreeStepModel from "./components/sub-pages/Threestep-model";
import Laddermodel from "./components/sub-pages/Laddermodel";
import StressScale from "./components/sub-pages/Stress-scale";
import ThoughtScale from "./components/sub-pages/Thought-scale";
import InterpretationModel from "./components/sub-pages/Interpretation-model";
import ThoughtLearnings from "./components/general/Thought-learnings";
import ThoughtList from "./components/sub-pages/Thought-list";
import { Routes, Route, useLocation, useNavigate } from "react-router";
import React, { useContext, useEffect } from "react";
import CareList from "./components/sub-pages/Care-list";

export const AudioContext = React.createContext({
  audio: null,
  url: null,
  stop: null,
  location: null,
});

const App = () => {
  const audioContext = useContext(AudioContext);
  const location = useLocation();
  const data = localStorage.getItem("authorized");
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname !== audioContext.location && audioContext.audio) {
      audioContext.audio.pause();
      audioContext.stop();
      audioContext.audio.removeEventListener("ended", audioContext.stop);
    }
  }, [location, audioContext, audioContext.location]);

  useEffect(() => {
    if (data !== "yes" && location.pathname !== "/") {
      navigate("/");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/valg" element={<NavScreen />} />
        <Route path="/sosiale-relasjoner" element={<SocialRelations />} />
        <Route path="/folelser" element={<Feelings />} />
        <Route path="/laerende-tankesett" element={<LearningMindset />} />
        <Route path="/problemlosning" element={<Problemsolving />} />
        <Route path="/oppmerksomhets-trening" element={<AttentionTraining />} />
        <Route path="/atlars" element={<Atlars />} />
        <Route path="/relasjonskart" element={<RelationMap />} />
        <Route
          path="/huskeliste-for-aa-starte-en-samtale"
          element={<ConversationList />}
        />
        <Route path="/ta-vare-paa-hverandre-plakaten" element={<CareList />} />
        <Route path="/tretrinnsmodellen" element={<ThreeStepModel />} />
        <Route path="/stige-modellen" element={<Laddermodel />} />
        <Route path="/stressskalaen" element={<StressScale />} />
        <Route path="/tenkeskalaen" element={<ThoughtScale />} />
        <Route path="/tolkningsmodellen" element={<InterpretationModel />} />
        <Route
          path="/hvordan-tenke-rundt-laering"
          element={<ThoughtLearnings />}
        />
        <Route
          path="/huskeliste-for-laerende-tankesett"
          element={<ThoughtList />}
        />
      </Routes>
    </div>
  );
};

export default App;
