import {createSlice} from "@reduxjs/toolkit";

export const mindMapSlice = createSlice({
    name: 'mindMap',
    initialState: {
        name: '',
        people: []
    },
    reducers: {
        setName(state, {payload}) {
            state.name = payload;
        }
    },
})

export const selectMindMap = state => state.mindMap;
export const {setName} = mindMapSlice.actions
export default mindMapSlice.reducer;
