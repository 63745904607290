import React from "react";
import "./Text-list.sass";

const TextList = ({ listItems }) => {
  const textList = listItems.map((item, index) => (
    <li key={index}>
      <p className="item-number">{index + 1}</p>
      <p>{item}</p>
    </li>
  ));

  return (
    <section className="text-list">
      <ul>{textList}</ul>
    </section>
  );
};

export default TextList;
